import './style.css';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

function section2() {
  // Textures
  const textureLoader = new THREE.TextureLoader();
  const matcapTexture = textureLoader.load('/textures/matcaps/matcap-3.png');

  // Canvas
  const canvas = document.querySelector('canvas.section-2--webgl');

  // Scene
  const scene = new THREE.Scene();
  // const axesHelper = new THREE.AxesHelper(1)
  // scene.add(axesHelper)

  // Material
  const material = new THREE.MeshMatcapMaterial();
  material.side = THREE.DoubleSide;
  material.matcap = matcapTexture;

  // gtlf Loader
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('/draco/');

  const gltfLoader = new GLTFLoader();
  gltfLoader.setDRACOLoader(dracoLoader);

  // Geometry
  let model;

  gltfLoader.load(
    '/models/extinctor.glb',
    (gltf) => {
      model = gltf.scene;
      model.scale.set(.1, .1, .1);
      // gui.add(model.rotation, 'x').min(0).max(Math.PI * 2).step(0.01)
      model.traverse((o) => {
        if (o.isMesh) o.material = material;
      });
      scene.add(model);
    }
  )

  // Sizes
  const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
  };

  window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      if (sizes.width < 768) {
        cameraZoom = 5;
      } else if (sizes.width > 768) {
        cameraZoom = 3.5;
      }
      camera.position.set(0,0,cameraZoom);
      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  // Camera
  let cameraZoom = 3.5
  const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100);
  camera.position.set(0,0,cameraZoom);
  scene.add(camera);

  // Controls
  // const controls = new TrackballControls(camera, canvas);
  // controls.enableDamping = true;

  // Renderer
  const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Animate
  const clock = new THREE.Clock();

  function tick() {
    const elapsedTime = clock.getElapsedTime();
    // Rotate model
    if (model) model.rotation.y = 0.5 * elapsedTime;
    // if (model) model.rotation.x = 0.1 * elapsedTime;
    // Update controls
    // controls.update();
    // Render
    renderer.render(scene, camera);
    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
}

export default section2;